.preload * {
  animation-duration: 0s !important;
  -webkit-animation-duration: 0s !important;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fly {
  from {
    opacity: 0;
    transform: translateY(var(--yStart));
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.animateFlyShort {
  --yStart: 10px;
  animation: fly 150ms ease-in-out;
}

.animateFlyLong {
  --yStart: 40px;
  animation: fly 200ms ease-in-out;
}

.animateFlyLongReverse {
  --yStart: -40px;
  animation: fly 200ms ease-in-out;
}
