/* Write your global styles here, in PostCSS syntax */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: -webkit-fill-available;
  @apply scrollbar-primary;
}

body {
  @apply bg-primary text-primary selection:bg-accent/25;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.grecaptcha-badge {
  visibility: hidden;
}

.linked-username {
  color: #fff;
  font-weight: 500;
}

/* ======================================================== */
@layer utilities {
  /* region HELPERS */
  .min-h-available {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  .max-w-screen {
    max-width: 100vw;
  }

  /* endregion */
  /* region SCROLLBARS */
  .scrollbar-primary::-webkit-scrollbar {
    @apply w-3;
  }

  .scrollbar-primary::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    @apply rounded-full bg-accent/40 bg-clip-content;
  }

  .scrollbar-secondary::-webkit-scrollbar {
    @apply w-3;
  }

  .scrollbar-secondary::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    @apply rounded-full bg-black/25 bg-clip-content;
  }

  .scrollbar-menu::-webkit-scrollbar {
    @apply w-3;
  }

  .scrollbar-menu::-webkit-scrollbar-track {
    @apply bg-white/[.16];
  }

  .scrollbar-menu::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    @apply rounded-full bg-white/30 bg-clip-content;
  }

  .scrollbar-menu-rounded {
    @apply scrollbar-menu;
  }

  .scrollbar-menu-rounded::-webkit-scrollbar-track {
    @apply rounded-r-xl;
  }

  /* endregion */

  @supports not (overflow-wrap: anywhere) {
    .break-anywhere {
      word-break: break-word;
    }
  }

  @supports (overflow-wrap: anywhere) {
    .break-anywhere {
      overflow-wrap: anywhere;
    }
  }
}

/* ======================================================== */
@layer components {
  /* region BUTTONS */
  .btn {
    @apply relative z-10 inline-block shrink-0 rounded-full bg-white bg-opacity-10 p-3 px-6 text-center font-medium transition-transform;
    @apply hover:scale-105;
    @apply focus:outline-accent;
    @apply disabled:bg-opacity-50;
    @apply disabled:cursor-not-allowed;
    @apply disabled:hover:scale-100;
  }

  .btn:active {
    @apply hover:scale-100;
  }

  .btn-square {
    @apply p-3;
  }

  .btn-primary {
    @apply bg-white text-black;
  }

  .btn-accent {
    @apply bg-accent text-white;
  }

  .btn-secondary {
    @apply bg-accent bg-opacity-20 text-accent;
  }

  .btn-secondary * {
    @apply text-accent;
  }

  .btn-tertiary {
    @apply text-accent;
  }

  .btn-ghost {
    @apply border border-accent bg-transparent text-accent;
  }

  .btn-dot::before {
    content: '';
    @apply -ml-1 mr-3 inline-block h-3 w-3 rounded-full bg-accent;
  }

  .btn-link {
    @apply rounded-none bg-transparent px-1 py-0;
  }

  .btn-icon {
    @apply flex items-center space-x-2;
  }

  .btn-icon svg {
    @apply -ml-1;
  }

  /* endregion */
  /* region TAGS */
  /* endregion */
  /* region MASONRY */
  .xmasonry {
    @apply m-1.5;
  }

  .xblock {
    @apply p-1.5;
  }

  /* endregion */
  /* region next/image hack */
  /* https://nextjs.org/docs/api-reference/next/image#known-browser-bugs */
  /*noinspection CssNonIntegerLengthInPixels*/
  @supports (font: -apple-system-body) and (-webkit-appearance: none) {
    img[loading='lazy'] {
      clip-path: inset(0.6px);
    }
  }
  /* endregion */
  /* region TABS */
  .tab {
    @apply flex flex-1 flex-col border-white py-4 text-white sm:flex-none sm:flex-row sm:items-center sm:pr-2;
  }

  .tab-active {
    @apply border-b-2 border-primary/30;
  }

  .tab-inactive {
    @apply border-b border-primary/20 text-opacity-75;
  }

  .progress-bar::before {
    content: '';
    @apply absolute -top-4 block h-10 w-full bg-transparent;
  }

  /* endregion */
}

/* ======================================================== */
@layer base {
  /*
      Fallback for iPad h-screen.
      Source: https://github.com/tailwindlabs/tailwindcss/discussions/4515#discussioncomment-5662338
    */
  @supports (-webkit-touch-callout: none) {
    .h-screen {
      height: 100vh;
      max-height: -webkit-fill-available !important;
    }
  }

  /**
     * Some extensions force websites to use dark color scheme.
     * Forcing dark scheme sets the iframe's color scheme to light, giving it a white background before the content loads.
     * Setting the color scheme to `light` for iFrame in that case prevents this from happening.
     * We can remove this when we are no longer using iframes in the app.
     */
  @media (prefers-color-scheme: dark) {
    iframe {
      color-scheme: light;
    }
  }
}
